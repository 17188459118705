import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../../components/layout'
import HeaderGeneric from '../../components/HeaderGeneric'


import v1 from '../../assets/images/voice.webp'
import v3 from '../../assets/images/voice3.webp'
import v4 from '../../assets/images/voice4.webp'

import voice from '../../assets/images/voice-icon.svg'


class VoiceRecorderPage extends React.Component {
    render() {

        return (
            <Layout>
                <Helmet title="Easy Voice - free, with sync to Google Drive | Asgaard Software" />
                <HeaderGeneric title="Easy Voice" subtitle="Asgaard Easy Voice - A simple, free voice recording app, which syncs to Google Drive" showHome="true" />
                <div id="main" class='recorder-page'>
                    <section id="content" className="main voice-recorder">
                        <h2> <img src={voice} /> Asgaard Easy Voice</h2>
                        <p> Asgaard Easy Voice is a simple and free voice recording app for Android. </p>
                        <div class='images app-promo'>
                            <img src={v1} alt="Record your thoughts, ideas and meetings" class='double' />
                            <img src={v3} alt="Enjoy a modern, dark mode user interface" />
                            <img src={v4} alt="Listen to, manage and share your recordings" />
                        </div>

                        <p> Easy Voice was created to make everyday recording simple. 
                            Most voice recorders on the market let you record audio, but often the phone's role is just to provide a microphone.
                            The phone itself is not really a reliable long term store of information, so after the recording is complete, 
                            users need a way to manage and move those recordings to somewhere more appropriate.
                        </p>
                        <p>
                            Plugging the phone into a PC and locating the recording files is a lot of hassle, and dare we say, a bit old fashioned. 
                            Easy Voice automatically uploads its recordings to the user's Google Drive account. Why Google Drive? 
                            Because every user installing the app through Google Play already has a Google Drive account.
                        </p>
                        <p> Once it's in Google Drive, users can listen to the recordings in their browser, sort them into appropriate locations
                            and download the raw file.
                        </p>

                        <p> Easy Voice is designed to make the workflow of recording and managing audio files as simple as possible.</p>

                        <div class='play-badge'>
                            <a href='https://play.google.com/store/apps/details?id=uk.co.asgaard.soundrecorder&amp;pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>
                        </div>
                    </section>
                </div>
            </Layout>
        )
    }
}

export default VoiceRecorderPage